import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Is Polis a goal oriented system?`}</h1>
    <p>{`Yes. `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` is an `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Intelligent_agent"
      }}>{`intelligent agent`}</a>{`, since it both percepts and acts. Perception comes in the form of `}<a parentName="p" {...{
        "href": "/votes",
        "title": "votes"
      }}>{`votes`}</a>{` and `}<a parentName="p" {...{
        "href": "/opinion-groups",
        "title": "opinion groups"
      }}>{`opinion groups`}</a>{` created from those votes. Votes on comments constitute the `}<a parentName="p" {...{
        "href": "/opinion-space",
        "title": "opinion space"
      }}>{`opinion space`}</a>{`, which we can consider the agent's environment. You may be surprised to learn that Polis does not perceive the content of the comments, but this is why Polis works in every language, a core design constraint. Polis only perceives (or 'uses') the `}<a parentName="p" {...{
        "href": "/opinion-matrix",
        "title": "opinion matrix"
      }}>{`opinion matrix`}</a>{` of votes to produce opinion groups, which themselves serve as an input to decisionmaking.`}</p>
    <p>{`Actions comes in the form of showing, in parellel and one at a time, `}<a parentName="p" {...{
        "href": "/comments",
        "title": "comments"
      }}>{`comments`}</a>{` to `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{`. `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` is able to act, that is in this case to choose, because of what Polis percepts about the `}<a parentName="p" {...{
        "href": "/opinion-space",
        "title": "opinion space"
      }}>{`opinion space`}</a>{`.`}</p>
    <h3>{`Does Polis learn?`}</h3>
    <p>{`In plain language, we can think of all intelligent agents as 'wanting something'. A thermostat wants your room to be a certain temperature, cruise control on your car wants you to stay at a certain speed, your autonomous vacuum wants your floor to be clean, and large language models want to predict the most likely next set of words given a prompt. What does Polis 'want'? How does it evaluate its own actions as good and bad? `}</p>
    <p>{`Polis 'considers' an action to be a good one if it:`}</p>
    <ul>
      <li parentName="ul">{`increases its confidence about newly submitted comments with few votes`}</li>
      <li parentName="ul">{`increases its confidence about the relationship between individuals to each other`}</li>
      <li parentName="ul">{`minimizes unnecessary voting`}</li>
    </ul>
    <p>{`Thus, the system does encode values. `}</p>
    <p>{`There's more, however. A regular thermostat does not update its model of the world: it just knows about temperature and can 'actuate' (which is a fancy word for 'do') by turning on and off the heat or air conditioning. Nest, the smart thermostat, learns about the preferences of the inhabitants and turns systems on and off dynamically based on an updating model of its environment, which includes not only the house but the preferences of the inhabitants. `}</p>
    <p>{`As Polis learns more, it changes its behavior based on what it learns. For instance, comment #42 might enter the system, and because it's new, it would decide to show it frequently to learn about it, but then, learning it was representative of a group, show it even more. Polis is a real time system, which means it's constantly updating its model of the world, which again in this case we consider the `}<a parentName="p" {...{
        "href": "/opinion-space",
        "title": "opinion space"
      }}>{`opinion space`}</a>{` to be. The `}<a parentName="p" {...{
        "href": "/model",
        "title": "model"
      }}>{`model`}</a>{` is described in detail in the `}<a parentName="p" {...{
        "href": "https://twitter.com/colinmegill/status/1445044310722822147"
      }}>{`foundational paper`}</a>{`.`}</p>
    <p>{`Under Russel and Norvig's (2003) classification of intelligent agents, Polis is a "model based, goal based agent". Perhaps Polis rises to a "learning agent" as each `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` starts as a completley unknown landscape with exactly zero votes. Polis has both a "learning element", the model (PCA and k means) and a "performance element" (`}<a parentName="p" {...{
        "href": "/comment-routing",
        "title": "comment routing"
      }}>{`comment routing`}</a>{`). In the future it is more likely Polis will grow into a full learning agent, because there are many opportunities for it to receive feedback on how its actions are affecting the world state, and thus learn about its performance. `}</p>
    <p>{`Notably, everything discussed above is scoped to a single conversation: Polis does not yet learn across conversations, to apply one set of structures to other conversations. That is, it does not for instance up weight comments of a certain length because it has found across all conversations that those comments tend to be more likely to gain widespread agreement. This is an interesting potential research area, but not without substantial risks.`}</p>
    <p>{`We can thus say that the goal of the Polis agent is to collect an `}<a parentName="p" {...{
        "href": "/opinion-matrix",
        "title": "opinion matrix"
      }}>{`opinion matrix`}</a>{` which optimally describes the opinion space which exists among participants, and minimize the number of input votes necessary to produce that optimal representation. Nothing's perfect without the full matrix, but since that's impossible to gather, think about optimal as "best available given certain circumstances" like comments submitted late into the conversation, and participants who only vote 12 or 15 times on 1000s of comments. `}</p>
    <h3>{`Goals of the platform`}</h3>
    <p>{`We must differentiate the goals of the agent from the goals of the platform as a whole. `}</p>
    <p>{`The presence of the visualization adds a feedback loop, in which the participants can view the present model and adapt their behavior in potentially profound ways. Because of the visualization, we cannot say that Polis as a platform does not have its own goals: by showing the model to the participants, there is an invitation for them to learn about themselves, and thus to change their minds, reflect, process, and engage in higher order analysis of their position within a landscape, and submit further comments and votes into the system after doing so. Thus the platform has a goal that people reflect.`}</p>
    <p>{`There is a rather heavy weight in Polis' visualization of opinion groups on what the "Majority Opinion" is. In this view, the platform reflects which statements were most likely to gain agreement (or disagreement) among all the opinion groups. Nature abhors a vaccuum, and anectodal evidence suggests participants do enjoy seeing their statements "win the game" and garner support from all sides. Polis can be considered a `}<a parentName="p" {...{
        "href": "/multi-agent",
        "title": "multi agent"
      }}>{`multi agent`}</a>{` system.`}</p>
    <p>{`By shutting the visualization off, the system is more purely gathering the opinion matrix which exists before people begin to interact. Because Polis is a wikisurvey, however, even reading statements submitted by others may prompt reflection, and thus while the goal in the design of the platform has been to gather the opinion landscape which exists, accurately, there was always the intention to change it, simultaneously, more than just the understanding that it `}<em parentName="p">{`might`}</em>{` change, with an understanding that humans form their opinions at least in part through their interactions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      